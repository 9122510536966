import * as React from "react";

type RecommendationTextProps = {
  recommendation: string;
};
const RecommendationText: React.FC<RecommendationTextProps> = props => {
  const { recommendation } = props;
  return (
    <div className="d-flex align-items-top">
      <i className="far fa-arrow-right mr-2 c-ddr-recommendation-bullet" />
      <div>{recommendation}</div>
    </div>
  );
};
export default RecommendationText;
