import React from "react";

// A hidden input field that sends out 'change' and 'input' events when it changes.

export default class EventfulHiddenInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value != prevProps.value) {
      const inputEvent = new CustomEvent("input", { bubbles: true });
      const changeEvent = new CustomEvent("change", { bubbles: true });
      this.inputRef.current.dispatchEvent(inputEvent);
      this.inputRef.current.dispatchEvent(changeEvent);
    }
  }

  render() {
    return <input type="hidden" {...this.props} ref={this.inputRef} />;
  }
}
