import React from "react";
import { PublicationData } from "./types";
import Publication from "./Publication";

type Props = {
  publications: PublicationData[];
  sendRefreshPublicationDataExport: (id: string) => void;
};

export default function PublicationList(props: Props) {
  const { publications, sendRefreshPublicationDataExport } = props;

  return (
    <table className="table table-striped table-sm">
      <colgroup>
        <col width="40%" />
        <col width="40%" />
        <col width="20%" />
      </colgroup>
      <thead>
        <tr>
          <th>Generated</th>
          <th>Released</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {publications.map(p => (
          <Publication
            publication={p}
            {...{ sendRefreshPublicationDataExport }}
            key={p.id}
          />
        ))}
      </tbody>
    </table>
  );
}
