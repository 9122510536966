import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export default function ReactSelect({ creatable, ...props }) {
  if (creatable) {
    return (
      <CreatableSelect isClearable {...props} {...{ formatCreateLabel }} />
    );
  } else {
    return <Select isClearable {...props} />;
  }
}

// Just display the label for new options without adding any text
function formatCreateLabel(string) {
  return string;
}
