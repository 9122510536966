import { ChannelNameWithParams, CreateMixin } from "@rails/actioncable";
import consumer from "./channels/consumer";

const createChannel = (
  channel: string | ChannelNameWithParams,
  obj?: CreateMixin
) => {
  console.log("createChannel", channel);
  return consumer.subscriptions.create(channel, obj);
};

export default createChannel;
