import React from "react";
import HeaderItem from "./HeaderItem";
import PreviewPublication from "./PreviewPublication";
import ReleasedPublication from "./ReleasedPublication";
import RemoveFromPublication from "./RemoveFromPublication";
import ConfirmationModal from "../ConfirmationModal";
import { DataContext } from "./DataContextProvider";
import PublicationListPortal from "./PublicationListPortal";

type Props = {};
export default function PublicationStatus(props: Props) {
  const { confirmationModal } = React.useContext(DataContext);

  return (
    <div className="o-summary-header mt-4">
      <HeaderItem
        title="Preview Publication (Analysts Only)"
        icon={<i className="fas fa-file-search" />}
      >
        <PreviewPublication />
      </HeaderItem>

      <HeaderItem
        title="Released Publication (Brand Users + Analysts)"
        icon={<i className="fas fa-file" />}
      >
        <ReleasedPublication />
      </HeaderItem>

      <HeaderItem
        title="Remove from Publication"
        icon={<i className="fas fa-trash" />}
      >
        <RemoveFromPublication />
      </HeaderItem>

      <ConfirmationModal {...confirmationModal} />
      <PublicationListPortal />
    </div>
  );
}
