import React from "react";
import { DataContext } from "./DataContextProvider";

export default function ReleasedPublication() {
  const {
    data: {
      releasedPublication: {
        available,
        timestamp,
        href,
        mayCreate,
        createHref,
        creationInProgress,
        previewAvailable,
        hasError
      }
    },
    sendCreateReleasedPublication,
    showConfirmationModal
  } = React.useContext(DataContext);

  const sendCreate = (event: React.MouseEvent) => {
    event.preventDefault();
    showConfirmationModal({
      body: (
        <p>
          This will make the data in the current Preview Publication available
          to brand users.
        </p>
      ),
      confirmPrompt: "Release Publication to Brands",
      rejectPrompt: "Cancel",
      onConfirm: sendCreateReleasedPublication
    });
  };

  let Availability;
  if (available) {
    Availability = (
      <div className="mb-2">
        <a href={href} target="_blank" rel="noopener noreferrer">
          Released {timestamp} <i className="far fa-external-link" />
        </a>
      </div>
    );
  } else {
    Availability = "";
  }

  let Creation;
  if (mayCreate) {
    Creation = (
      <div className="mb-2">
        <a
          className="btn btn-sm btn-outline-primary"
          href={createHref}
          onClick={sendCreate}
        >
          Release Preview Publication to Brands
        </a>
      </div>
    );
  } else if (creationInProgress) {
    Creation = (
      <div className="mb-2">
        <i className="fas fa-spinner fa-pulse" /> Releasing Publication to
        Brands
      </div>
    );
  } else if (!previewAvailable) {
    Creation = (
      <div className="mb-2">
        <i>To release new data to brands, first create a preview publication</i>
      </div>
    );
  }

  let Error;
  if (hasError) {
    Error = (
      <div className="mb-2">
        <i className="fas fa-exclamation-triangle text-warning" />{" "}
        <i>There was an error releasing the publication</i>
      </div>
    );
  }

  return (
    <>
      {Availability}
      {Creation}
      {Error}
    </>
  );
}
