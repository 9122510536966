import { useState } from "react"
import Switch from "react-switch"

function DdaRemindersToggle(props) {
  const { checked, updateUrl } = props
  const [state, setState] = useState({ checked })

  const labelText = state.checked
    ? "Automatic Email Reminders are Enabled"
    : "Automatic Email Reminders are Disabled"

  const handleChange = _event => {
    const newChecked = !state.checked
    setState({ checked: newChecked })
    $.ajax({
      url: updateUrl,
      method: "POST",
      data: { automatic_reminders_enabled: newChecked },
      error: () =>
        alert(
          "Sorry an error occurred and your changes were not saved.  Please reload the page."
        )
    })
  }

  return (
    <label className="d-flex align-items-center">
      <Switch onChange={handleChange} checked={state.checked} />
      <span className="ml-1">
        {/* <LabelText checked={state.checked} /> */}
        {labelText}
      </span>
    </label>
  )
}

export default DdaRemindersToggle
