import React from "react";
import { Editor } from "@tinymce/tinymce-react";
// import "tinymce/tinymce";
// import "tinymce/themes/silver";
// import "tinymce/plugins/paste";
// import "tinymce/plugins/lists";
// import "tinymce/plugins/advlist";

type Props = {
  initialContent: string;
  inputName: string;
  disabled: boolean;
};
export default function DdrSectionSummaryEditor(props: Props) {
  const { initialContent, inputName, disabled } = props;
  const [inputValue, setInputValue] = React.useState(initialContent);

  const handleChange = (event: any) => setInputValue(event.target.getContent());

  if (disabled) {
    let disabledContent = initialContent;
    if (!disabledContent) {
      disabledContent = "<i>No Summary has been entered</i>";
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: disabledContent
        }}
      ></div>
    );
  } else {
    return (
      <>
        <Editor
          initialValue={initialContent}
          init={{
            height: 420,
            menubar: false,
            plugins: ["paste lists advlist"],
            toolbar:
              "formatselect | bold italic underline | forecolor backcolor removeformat |  \
            bullist numlist | outdent indent |  \
            alignleft aligncenter alignright alignjustify | \
            undo redo |",
            browser_spellcheck: true,
            contextmenu: false,
            skin_url: "/skins/ui/oxide",
            content_css: "/tinymce.css"
          }}
          onChange={handleChange}
        />
        <input name={inputName} type="hidden" value={inputValue} />
      </>
    );
  }
}
