import * as React from "react";
import RecommendationsList from "./RecommendationsList";
import {
  RecommendationChoicesModal,
  AddRecommendationButton
} from "./AddRecommendationsModal";
import RecommendationChoice from "./RecommendationChoice";
import cloneDeep from 'lodash/cloneDeep';

/////////////////////////////////////////////
// DdrSectionRecommendationsFields
/////////////////////////////////////////////
type DdrSectionRecommendationsFieldsProps = {
  allRecommendations: string[];
  initialRecommendations: string[];
  disabled: boolean;
};

const DdrSectionRecommendationsFields: React.FC<DdrSectionRecommendationsFieldsProps> = props => {
  const { allRecommendations, initialRecommendations, disabled } = props;

  const [recommendations, setRecommendations] = React.useState(
    initialRecommendations
  );
  const [modalState, setModalState] = React.useState({ isOpen: false });

  const removeRecommendation = (index: number) => {
    const newRecommendations = cloneDeep(recommendations);
    newRecommendations.splice(index, 1);
    setRecommendations(newRecommendations);
  };

  const pushRecommendation: (r: string) => void = recommendation => {
    const newRecommendations = cloneDeep(recommendations);
    newRecommendations.push(recommendation);
    setRecommendations(newRecommendations);
  };

  const moveRecommendation = (from: number, to: number) => {
    const newRecommendations = cloneDeep(recommendations);
    const [removed] = newRecommendations.splice(from, 1);
    newRecommendations.splice(to, 0, removed);
    setRecommendations(newRecommendations);
  };

  const getRecommendationId: (r: string) => number = recommendation => {
    return allRecommendations.indexOf(recommendation);
  };

  const closeModal: () => void = () => {
    setModalState({ isOpen: false });
  };

  const choices = allRecommendations.map((r: string, i: number) => {
    const choiceDisabled: boolean = recommendations.includes(r);

    return (
      <RecommendationChoice
        recommendation={r}
        disabled={choiceDisabled}
        {...{ pushRecommendation, closeModal }}
        key={i}
      />
    );
  });

  return (
    <div>
      <RecommendationsList
        {...{
          removeRecommendation,
          name,
          recommendations,
          moveRecommendation,
          getRecommendationId,
          disabled
        }}
      />
      {!disabled && <AddRecommendationButton {...{ setModalState }} />}
      {!disabled && (
        <RecommendationChoicesModal
          {...{
            choices,
            modalState,
            closeModal,
            pushRecommendation
          }}
        />
      )}
    </div>
  );
};

export default DdrSectionRecommendationsFields;
