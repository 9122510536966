import * as React from "react";
import classnames from "classnames";
import RecommendationText from "./RecommendationText";
/////////////////////////////////////////////
// RecommendationChoice
/////////////////////////////////////////////
export type RecommendationChoiceProps = {
  recommendation: string;
  pushRecommendation: (recommendation: string) => void;
  closeModal: () => void;
  disabled: boolean;
};
const RecommendationChoice: React.FC<RecommendationChoiceProps> = props => {
  const { recommendation, pushRecommendation, closeModal, disabled } = props;

  const handleClick: React.EventHandler<React.MouseEvent> = event => {
    event.preventDefault();
    if (!disabled) {
      pushRecommendation(recommendation);
      closeModal();
    }
  };

  const className = classnames({
    "c-ddr-section-recommendation-choice": true,
    "c-ddr-section-recommendation-choice__disabled": disabled
  });

  return (
    <div {...{ className }} onClick={handleClick}>
      <RecommendationText recommendation={recommendation} />
    </div>
  );
};

export default RecommendationChoice;
