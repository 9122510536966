import React from "react";
import { PublicationData } from "./types";
import { UncontrolledTooltip } from "reactstrap";

type Props = {
  publication: PublicationData;
  sendRefreshPublicationDataExport: (id: string) => void;
};
export default function StatusIndicator(props: Props) {
  const {
    publication: {
      id,
      may_queue_data_export,
      data_export_status,
      data_export_url
    },
    sendRefreshPublicationDataExport
  } = props;

  const handleRefreshDataExport = (event: React.MouseEvent) => {
    event.preventDefault();
    sendRefreshPublicationDataExport(id);
  };

  if (!may_queue_data_export && data_export_status != "processing_complete") {
    return null;
  }

  switch (data_export_status) {
    case "not_started":
      return may_queue_data_export ? (
        <>
          <a
            href={data_export_url}
            data-method="POST"
            id={`createDataExportLink-${id}`}
            onClick={handleRefreshDataExport}
          >
            <i className="fas fa-redo" />
          </a>
          <UncontrolledTooltip
            placement="bottom"
            target={`createDataExportLink-${id}`}
          >
            Create data export.
          </UncontrolledTooltip>
        </>
      ) : (
        <></>
      );
    case "queued":
    case "processing":
      return (
        <>
          <a id={`dataExportInProgressIcon-${id}`}>
            <i className="fas fa-spinner fa-pulse" />
          </a>
          <UncontrolledTooltip
            placement="bottom"
            target={`dataExportInProgressIcon-${id}`}
          >
            Building Data Export.
          </UncontrolledTooltip>
        </>
      );
    case "processing_complete":
      return (
        <>
          <a
            href={data_export_url}
            target="_blank"
            rel="noopener noreferrer"
            id={`dataExportLink-${id}`}
          >
            <i className="far fa-file-excel" />
          </a>
          <UncontrolledTooltip
            placement="bottom"
            target={`dataExportLink-${id}`}
          >
            Download Data Export.
          </UncontrolledTooltip>
          {may_queue_data_export && (
            <>
              <a
                href={data_export_url}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Regenerate Data Export with current Shared/Public Data."
                className="ml-2"
                id={`createDataExportLink-${id}`}
                onClick={handleRefreshDataExport}
              >
                <i className="fas fa-redo" />
              </a>
              <UncontrolledTooltip
                placement="bottom"
                target={`createDataExportLink-${id}`}
              >
                Rebuild data export with most recent shared data.
              </UncontrolledTooltip>
            </>
          )}
        </>
      );
    case "error":
      return (
        <>
          <a
            href={data_export_url}
            data-method="POST"
            data-toggle="tooltip"
            data-placement="bottom"
            id={`createDataExportLink-${id}`}
            onClick={handleRefreshDataExport}
          >
            <i className="far fa-exclamation-triangle" />
          </a>
          <UncontrolledTooltip
            placement="bottom"
            target={`createDataExportLink-${id}`}
          >
            There was an error generating the data export. Click here to try
            again.
          </UncontrolledTooltip>
        </>
      );
  }
}
