/* global JSX */
import * as React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

/////////////////////////////////////////////
// AddRecommendationButton
/////////////////////////////////////////////
interface AddRecommendationButtonProps {
  setModalState: (obj: any) => void;
}
export const AddRecommendationButton: React.FC<AddRecommendationButtonProps> = function(
  props
) {
  const { setModalState } = props;
  const handleClick: React.EventHandler<React.MouseEvent> = event => {
    event.preventDefault();
    setModalState({ isOpen: true });
  };
  return (
    <a href="#" onClick={handleClick}>
      <i className="far fa-plus-circle" /> Add Recommendation
    </a>
  );
};

/////////////////////////////////////////////
// RecommendationChoicesModal
/////////////////////////////////////////////
interface RecommendationChoicesModalProps {
  modalState: { isOpen: boolean };
  closeModal: () => void;
  pushRecommendation: (obj: string) => void;
  choices: JSX.Element[];
}
export const RecommendationChoicesModal: React.FC<RecommendationChoicesModalProps> = function(
  props
) {
  const {
    modalState: { isOpen },
    choices,
    closeModal
  } = props;

  return (
    <Modal {...{ isOpen }} className="modal-lg">
      <ModalBody>{choices}</ModalBody>
      <ModalFooter>
        <CloseModalButton {...{ closeModal }} />
      </ModalFooter>
    </Modal>
  );
};

/////////////////////////////////////////////
// CloseModalButton
/////////////////////////////////////////////
type CloseModalButtonProps = {
  closeModal: () => void;
};
const CloseModalButton: React.FC<CloseModalButtonProps> = props => {
  const { closeModal } = props;
  const handleClick: React.EventHandler<React.MouseEvent> = event => {
    event.preventDefault();
    closeModal();
  };
  return (
    <a className="btn btn-outline-primary" href="#" onClick={handleClick}>
      Cancel
    </a>
  );
};
