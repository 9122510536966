import React from "react";
import classnames from "classnames";

type Props = {
  title: string;
  icon?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
  hidden?: boolean;
};
export default function HeaderItem(props: Props) {
  const { title, icon, children, hidden } = props;

  const containerClass = classnames({
    "o-summary-header__item": true,
    "flex-basis-100": true,
    "d-none": hidden
  });

  return (
    <div className={containerClass}>
      <dt>
        {icon} {title}
      </dt>
      <dd>{children}</dd>
    </div>
  );
}
