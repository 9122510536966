import React from "react";
import { Editor } from "@tinymce/tinymce-react";

type Props = {
  initialContent: string;
  inputName: string;
  uploadUrl: string;
};
export default function ContentBlockEditor(props: Props) {
  const { initialContent, inputName, uploadUrl } = props;
  const [inputValue, setInputValue] = React.useState(initialContent);

  const handleChange = (event: any) => {
    setInputValue(event.target.getContent());

    const domTarget = event.target.targetElm;
    const changeEvent = document.createEvent("HTMLEvents");
    changeEvent.initEvent("change", true, false);
    domTarget.dispatchEvent(changeEvent);
  };

  return (
    <>
      <Editor
        initialValue={initialContent}
        init={{
          height: "calc(100vh - 320px)",
          menubar: false,
          plugins: ["paste lists advlist image link anchor"],
          toolbar:
            "formatselect | bold italic underline | forecolor backcolor removeformat |  \
            bullist numlist | outdent indent |  \
            alignleft aligncenter alignright alignjustify | \
            image link anchor | \
            undo redo |",
          browser_spellcheck: true,
          contextmenu: false,
          skin_url: "/skins/ui/oxide",
          content_css: "/tinymce.css",
          images_upload_url: uploadUrl,
          image_advtab: true
        }}
        onChange={handleChange}
      />
      <input name={inputName} type="hidden" value={inputValue || ""} />
    </>
  );
}
