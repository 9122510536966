import React from "react";
import { DataContext } from "./DataContextProvider";

export default function PreviewPublication() {
  const {
    data: {
      previewPublication: {
        available,
        timestamp,
        href,
        mayCreate,
        createHref,
        creationInProgress,
        companyReadyForPublishing,
        hasError
      },
      companyName
    },
    sendCreatePreviewPublication,
    showConfirmationModal
  } = React.useContext(DataContext);

  const sendCreate = (event: React.MouseEvent) => {
    event.preventDefault();
    showConfirmationModal({
      body: (
        <p>
          This will create a new Preview Publication for {companyName}. New data
          will not be visible to brand users until the Preview Publication is
          released.
        </p>
      ),
      confirmPrompt: "Create Preview Publication",
      rejectPrompt: "Cancel",
      onConfirm: sendCreatePreviewPublication
    });
  };

  let Availability;
  if (available) {
    Availability = (
      <div className="mb-2">
        <a href={href} target="_blank" rel="noopener noreferrer">
          Generated {timestamp} <i className="far fa-external-link" />
        </a>
      </div>
    );
  } else {
    Availability = "";
  }

  let Creation;
  if (mayCreate) {
    Creation = (
      <div className="mb-2">
        <a
          className="btn btn-sm btn-outline-primary"
          href={createHref}
          onClick={sendCreate}
        >
          Create Preview Publication
        </a>
      </div>
    );
  } else if (creationInProgress) {
    Creation = (
      <div className="mb-2">
        <i className="fas fa-spinner fa-pulse" />{" "}
        <i>Generating Preview Publication </i>
      </div>
    );
  } else if (!companyReadyForPublishing) {
    Creation = (
      <div className="mb-2">
        <i>
          To create a preview publication, first mark all evidence Approved or
          Excluded
        </i>
      </div>
    );
  } else {
    Creation = "";
  }

  let Error;
  if (hasError) {
    Error = (
      <div className="mb-2">
        <i className="fas fa-exclamation-triangle text-warning" />{" "}
        <i>There was an error generating the preview publication</i>
      </div>
    );
  }

  if (available) {
    // show link
  }

  if (mayCreate) {
    // show button
  }

  if (!mayCreate && !companyReadyForPublishing) {
    // show message
  }

  if (hasError) {
    // show error flag
  }

  return (
    <>
      {Availability}
      {Creation}
      {Error}
    </>
  );
}
