import React from "react";
import { PublicationStatusState, StatusChannelIdentifier } from "./types";
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import assign from 'lodash/assign';
import find from 'lodash/find';
import Rails from "rails-ujs";
import { ConfirmationModalContent } from "../ConfirmationModal";
import { PublicationData } from "../PublicationList/types";
import createChannel from "../../cable";

const confirmationModalDefault = {
  isOpen: false,
  body: "",
  confirmPrompt: "",
  rejectPrompt: "",
  onConfirm: () => {},
  close: () => {}
};

export const DataContext = React.createContext({
  data: {
    previewPublication: {
      available: false,
      timestamp: "Jan 1, 2020 12:00 AM EST",
      href: "#",
      mayCreate: false,
      createHref: "#",
      creationInProgress: false,
      companyReadyForPublishing: false,
      hasError: false
    },
    releasedPublication: {
      available: false,
      timestamp: "Jan 1, 2020 12:00 AM EST",
      href: "#",
      mayCreate: false,
      createHref: "#",
      creationInProgress: false,
      previewAvailable: false,
      hasError: false
    },
    removeFromPublication: {
      hasReleasedPublication: false,
      mayUnpublish: false,
      unpublishHref: "#",
      unpublishingInProgress: false,
      hasError: false
    },
    getStatusUrl: "#",
    companyName: "Test Company",
    publications: [
      {
        id: "",
        created_at: "",
        released_at: "",
        type: "publishing",
        data_export_status: "not_started",
        data_export_url: "",
        may_queue_data_export: false
      }
    ]
  },
  updateContextData: (keyPath: string | string[], attrs: {}) => {},
  sendCreatePreviewPublication: () => {},
  sendCreateReleasedPublication: () => {},
  sendUnpublish: () => {},
  sendRefreshPublicationDataExport: (id: string) => {},
  confirmationModal: cloneDeep(confirmationModalDefault),
  closeConfirmationModal: () => {},
  showConfirmationModal: (content: ConfirmationModalContent) => {}
});

type Props = {
  initialState: PublicationStatusState;
  statusChannelIdentifier: StatusChannelIdentifier;
  children: JSX.Element[] | JSX.Element;
};

export default function DataContextProvider(props: Props) {
  const { initialState, statusChannelIdentifier, children } = props;

  const [data, setData] = React.useState(initialState);

  // helper functions
  const updateContextData = (keyPath: string | string[], attrs: {}) => {
    const newData = cloneDeep(data);
    const dataChild = get(newData, keyPath);
    assign(dataChild, attrs);
    setData(newData);
  };

  const postAndRefreshData = async (url: string) => {
    const response = await fetch(url, {
      method: "post",
      body: JSON.stringify({ authenticity_token: Rails.csrfToken() }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const newData = await response.json();
    setData(newData);
  };

  // initiate actions on the server
  const sendCreatePreviewPublication = () => {
    updateContextData("previewPublication", {
      mayCreate: false,
      creationInProgress: true,
      hasError: false
    });
    postAndRefreshData(data.previewPublication.createHref);
  };

  const sendCreateReleasedPublication = async () => {
    updateContextData("releasedPublication", {
      mayCreate: false,
      creationInProgress: true,
      hasError: false
    });
    postAndRefreshData(data.releasedPublication.createHref);
  };

  const sendUnpublish = async () => {
    updateContextData("removeFromPublication", {
      mayUnpublish: false,
      unpublishingInProgress: true,
      hasError: false
    });
    postAndRefreshData(data.removeFromPublication.unpublishHref);
  };

  const sendRefreshPublicationDataExport = async (id: string) => {
    const newData = cloneDeep(data);
    const publication = find(
      newData.publications,
      p => p.id === id
    ) as PublicationData;
    publication.data_export_status = "queued";
    newData.publicationsNeedRefresh = true;

    setData(newData);

    postAndRefreshData(publication.data_export_url);
  };

  React.useEffect(() => {
    createChannel(statusChannelIdentifier, {
      received: (message: { publicationStatus: PublicationStatusState }) =>
        setData(message.publicationStatus)
    });
  }, [statusChannelIdentifier]);

  // Setup Confirmation Modal
  const [confirmationModal, setConfirmationModal] = React.useState(
    confirmationModalDefault
  );

  const closeConfirmationModal = () => {
    setConfirmationModal(cloneDeep(confirmationModalDefault));
  };

  const showConfirmationModal = (content: ConfirmationModalContent) => {
    const newState = cloneDeep(confirmationModal);
    assign(newState, content);
    newState.isOpen = true;
    newState.close = closeConfirmationModal;
    setConfirmationModal(newState);
  };

  return (
    <DataContext.Provider
      value={{
        data,
        updateContextData,
        sendCreatePreviewPublication,
        sendCreateReleasedPublication,
        sendUnpublish,
        sendRefreshPublicationDataExport,
        confirmationModal,
        closeConfirmationModal,
        showConfirmationModal
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
