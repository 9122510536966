import React from "react";
import { PublicationData } from "./types";
import StatusIndicator from "./StatusIndicator";

type Props = {
  publication: PublicationData;
  sendRefreshPublicationDataExport: (id: string) => void;
};
export default function Publication(props: Props) {
  const {
    publication,
    publication: { created_at, released_at, type },
    sendRefreshPublicationDataExport
  } = props;

  if (type === "unpublishing") {
    return (
      <tr>
        <td colSpan={3} className="text-center">
          Removed from Publication {created_at}
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td>{created_at}</td>
        <td>{released_at}</td>
        <td>
          <StatusIndicator
            {...{ publication, sendRefreshPublicationDataExport }}
          />
        </td>
      </tr>
    );
  }
}
