import { Fragment, useState, useEffect } from "react";
import { Formik, Field } from "formik";
import Select from "react-select";
import sumBy from "lodash/sumBy";
import capitalize from "lodash/capitalize";

export default function WorkerFlowForm(props) {
  return (
    <Formik
      initialValues={props.initialValues}
      initialErrors={props.initialErrors}
    >
      {formikBag => <FormFields {...formikBag} {...props} />}
    </Formik>
  );
}

function FormFields(props) {
  const {
    originEntityOptions,
    destinationEntityOptions,
    countryOptions,
    initialUpstreamFlowState,
    upstreamFlowUrl,
    values: {
      worker_flow: {
        origin_entity_id,
        destination_entity_id,
        country_id,
        is_no_agent_flow
      }
    },
    initialErrors: {
      worker_flow: {
        origin_entity_id: originEntityError,
        destination_entity_id: destinationEntityError,
        country: countryError
      }
    }
  } = props;

  const upstreamFlowState = useFetchUpstreamFlows({
    url: upstreamFlowUrl,
    params: {
      origin_entity_id: origin_entity_id,
      destination_entity_id,
      country_id: country_id
    },
    initialState: initialUpstreamFlowState
  });

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-9">
          <input type="hidden" name={inputName("origin_entity_id")} value="" />
          <Field
            component={SelectField}
            name={inputName("origin_entity_id")}
            labelContent="Origin Entity"
            options={originEntityOptions}
            errorMessage={originEntityError}
            disabled={is_no_agent_flow}
          />
          <div className="row">
            <div className="col-sm-6">
              <Field
                component={IsNoAgentFlowField}
                name={inputName("is_no_agent_flow")}
              />
            </div>
            <div className="col-sm-6">
              <Field
                component={NoAgentTypeField}
                name={inputName("no_agent_flow_type")}
              />
            </div>
          </div>
          <Field
            component={SelectField}
            name={inputName("destination_entity_id")}
            labelContent="Destination Entity"
            options={destinationEntityOptions}
            errorMessage={destinationEntityError}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <Field
            component={SelectField}
            name={inputName("country_id")}
            labelContent="Worker Country of Origin"
            options={countryOptions}
            errorMessage={countryError}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-9">
          <Field
            component={WorkerCountField}
            name={inputName("worker_count")}
            upstreamflowstate={upstreamFlowState}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <UpstreamFlowTable {...upstreamFlowState} />
        </div>
      </div>
    </Fragment>
  );
}

function SelectField({ field, form, ...props }) {
  const { labelContent, options, errorMessage } = props;
  const isDisabled = props.disabled || false;
  const { name } = field;
  const value = options
    ? options.find(option => option.value == field.value)
    : "";
  const onChange = option => form.setFieldValue(name, option.value);
  const selectStyles = {
    menu: (provided, _state) => ({ ...provided, "z-index": "3" })
  };

  let errorHtml = "";
  if (errorMessage) {
    errorHtml = (
      <small className="help-block form-control-feedback">{errorMessage}</small>
    );
  }

  return (
    <div className="form-group">
      <label htmlFor={name} className="form-control-label">
        {labelContent}
      </label>
      <Select
        {...{ options, value, name, onChange, isDisabled }}
        isSearchable={true}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={selectStyles}
      />
      {errorHtml}
    </div>
  );
}

function WorkerCountField({ field, form, ...props }) {
  const { setFieldValue, values } = form;
  const { value } = field;
  const { upstreamflowstate } = props;

  return (
    <div className="form-group">
      <label htmlFor="workerCountInput" className="form-control-label">
        Worker Count{" "}
        <WorkerCountWarning
          currentWorkerCount={value}
          formValues={form.values}
          {...{ upstreamflowstate }}
        />
      </label>
      <div className="input-group">
        <input
          type="number"
          className="form-control"
          {...field}
          {...props}
          id="workerCountInput"
        />
        <SetWorkerCountButton
          {...{ setFieldValue, upstreamflowstate }}
          formValues={values}
        />
      </div>
    </div>
  );
}

function UpstreamFlowTable(props) {
  const { upstreamFlows, hasUpstreamFlows } = props;
  const totalWorkers = sumBy(upstreamFlows, f => f.worker_count);

  if (!hasUpstreamFlows) {
    return "";
  } else {
    return (
      <div className="mt-2">
        <label className="form-control-label">Upstream Flows</label>
        <table className="table table-sm">
          <thead>
            <tr>
              <th className="font-weight-normal">Sending Agent</th>
              <th className="font-weight-normal">Count</th>
            </tr>
          </thead>
          <tbody>
            {upstreamFlows.map((f, i) => (
              <UpstreamFlowRow flow={f} key={i} />
            ))}
            <tr>
              <td className="font-weight-bold">Total</td>
              <td className="font-weight-bold">{totalWorkers}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

function UpstreamFlowRow({ flow }) {
  return (
    <tr>
      <td>
        <EntityNameWithIcon entity={flow.origin_entity} />
      </td>
      <td style={{ paddingTop: "16px" }}>{flow.worker_count}</td>
    </tr>
  );
}

function EntityNameWithIcon(props) {
  const { name, entity_type, icon_type } = props.entity;

  return (
    <div className="d-flex align-items-center c-entity-name-with-icon">
      <span
        className="table-list__icon mr-2 mb-0"
        title={capitalize(entity_type)}
        data-toggle="tooltip"
      >
        <EmbeddedSVG symbol={icon_type} />
      </span>
      {name}
    </div>
  );
}

function EmbeddedSVG(props) {
  const xlinkHref = `#icon-${props.symbol}`;
  return (
    <div className="icon-svg">
      <svg>
        <use xlinkHref={xlinkHref}></use>
      </svg>
    </div>
  );
}

// TODO This is pretty generic.  Rename vars and abstract out to library
function useFetchUpstreamFlows(args) {
  const { url, params, initialState } = args;

  const queryUrl = `${url}?${$.param(params)}`;

  const [upstreamFlowState, setUpstreamFlowState] = useState(initialState);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetch(queryUrl);
      const json = await resp.json();
      setUpstreamFlowState(json);
    };
    fetchData();
  }, [queryUrl]);

  return upstreamFlowState;
}

function WorkerCountWarning(props) {
  const {
    upstreamflowstate: { hasUpstreamFlows, expectedWorkerCount },
    currentWorkerCount
  } = props;

  const isVisible =
    hasUpstreamFlows && currentWorkerCount != expectedWorkerCount;

  if (isVisible) {
    return (
      <span>
        <i className="fa fa-warning text-warning"></i>{" "}
        <i>Current value does not match total of upstream flows</i>
      </span>
    );
  } else {
    return "";
  }
}

function SetWorkerCountButton(props) {
  const {
    setFieldValue,
    upstreamflowstate: { hasUpstreamFlows, expectedWorkerCount }
  } = props;

  const handleClick = event => {
    event.preventDefault();
    setFieldValue("worker_flow[worker_count]", expectedWorkerCount);
  };

  const btnStyle = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  };

  if (hasUpstreamFlows) {
    return (
      <div className="input-group-append">
        <button
          className="btn btn-outline-primary"
          onClick={handleClick}
          style={btnStyle}
        >
          Determine Value from Upstream Flows
        </button>
      </div>
    );
  } else {
    return "";
  }
}

function NoAgentTypeField(props) {
  const {
    field: { name, value, onChange },
    form
  } = props;
  const visible = form?.values?.worker_flow?.is_no_agent_flow;
  if (!visible) {
    return <input type="hidden" value="" name={name} />;
  }
  return (
    <>
      <div className="form-check mb-2">
        <input
          type="radio"
          value="abroad"
          checked={value === "abroad"}
          id={`${name}-abroad`}
          className="form-check-input"
          {...{ name, onChange }}
        />
        <label htmlFor={`${name}-abroad`} className="form-check-label">
          From Abroad
        </label>
      </div>
      <div className="form-check mb-2">
        <input
          type="radio"
          value="walk-in"
          checked={value === "walk-in"}
          id={`${name}-walk-in`}
          className="form-check-input"
          {...{ name, onChange }}
        />
        <label htmlFor={`${name}-walk-in`} className="form-check-label">
          Walk-In
        </label>
      </div>
    </>
  );
}

function IsNoAgentFlowField(props) {
  const {
    field: { name, value, onChange }
  } = props;
  const isChecked = !!value;

  return (
    <div className="form-check mb-2">
      <input type="hidden" name={name} value="0" />
      <input
        type="checkbox"
        value="1"
        checked={isChecked}
        id={name}
        className="form-check-input"
        {...{ name, onChange }}
      />
      <label htmlFor={name} className="form-check-label">
        No Origin Agent
      </label>
    </div>
  );
}

function inputName(attrName) {
  return `worker_flow[${attrName}]`;
}
