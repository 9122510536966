import React from "react";
import ReactDOM from "react-dom";

import PublicationList from "../PublicationList";
import { DataContext } from "./DataContextProvider";
import { PublicationData } from "../PublicationList/types";

export default function PublicationListPortal(props: {}) {
  const {
    data: { publications },
    sendRefreshPublicationDataExport
  } = React.useContext(DataContext);

  const typedPublications = publications as PublicationData[];

  return ReactDOM.createPortal(
    <PublicationList
      publications={typedPublications}
      {...{ sendRefreshPublicationDataExport }}
    />,
    document.querySelector(".j-publication-list-container") as Element
  );
}
