import React from "react";
import { Modal, ModalBody } from "reactstrap";

// these bits are customized by the opener
export type ConfirmationModalContent = {
  body: string | JSX.Element | JSX.Element[];
  confirmPrompt: string | JSX.Element | JSX.Element[];
  rejectPrompt: string | JSX.Element | JSX.Element[];
  onConfirm: () => void;
};

export type ConfirmationModalProps = {
  isOpen: boolean;
  close: () => void;
} & ConfirmationModalContent;

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { isOpen, onConfirm, close, body, confirmPrompt, rejectPrompt } = props;

  const handleConfirm = (event: React.MouseEvent) => {
    event.preventDefault();
    onConfirm();
    close();
  };

  const handleReject = (event: React.MouseEvent) => {
    event.preventDefault();
    close();
  };

  return (
    <Modal modalClassName="modal modal--confirm fade" {...{ isOpen }}>
      <ModalBody>
        {body}
        <div className="form__actions text-right mt-5 mr-2">
          <a href="#" className="btn btn-secondary" onClick={handleReject}>
            {rejectPrompt}
          </a>
          <a href="#" className="btn btn-primary" onClick={handleConfirm}>
            {confirmPrompt}
          </a>
        </div>
      </ModalBody>
    </Modal>
  );
}
