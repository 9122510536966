import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import RecommendationText from "./RecommendationText";

interface Props {
  id: number;
  value: string;
  handleRemove: React.EventHandler<React.MouseEvent>;
  index: number;
  disabled: boolean;
}
export default function ChosenRecommendation(props: Props) {
  const { value, handleRemove, id, index, disabled } = props;

  const draggableId = "recommendation-" + id;

  const inputName = `cumulus_ddr_super_section[recommendations][]`;

  return (
    <Draggable {...{ draggableId, index }} isDragDisabled={disabled}>
      {(provided, _snapshot) => (
        <div
          className="d-flex align-items-center mb-3"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {!disabled && (
            <i className="far fa-arrows-v mr-2 c-ddr-chosen-recommendation-icon-drag" />
          )}

          <div className="p-2 border">
            <RecommendationText recommendation={value} />
          </div>
          {!disabled && (
            <a href="#" onClick={handleRemove} className="text-danger ml-2">
              <i
                className="far fa-times"
                c-ddr-chosen-recommendation-icon-remove
              />
            </a>
          )}

          <input type="hidden" name={inputName} value={value} />
        </div>
      )}
    </Draggable>
  );
}
