import React from "react";
import PublicationStatus from "./PublicationStatus";
import { PublicationStatusState, StatusChannelIdentifier } from "./types";
import DataContextProvider from "./DataContextProvider";

type Props = {
  initialState: PublicationStatusState;
  statusChannelIdentifier: StatusChannelIdentifier;
};

export default function(props: Props) {
  const { initialState, statusChannelIdentifier } = props;

  return (
    <DataContextProvider {...{ initialState, statusChannelIdentifier }}>
      <PublicationStatus />
    </DataContextProvider>
  );
}
