import React from "react";
import { DataContext } from "./DataContextProvider";

export default function RemoveFromPublication() {
  const {
    data: {
      removeFromPublication: {
        mayUnpublish,
        unpublishHref,
        unpublishingInProgress,
        hasError
      },
      companyName
    },
    sendUnpublish,
    showConfirmationModal
  } = React.useContext(DataContext);

  const handleSendUnpublish = (event: React.MouseEvent) => {
    event.preventDefault();
    showConfirmationModal({
      body: (
        <p>
          This will remove {companyName} from publication. Brand users will see
          a splash page on the company dashboard.
        </p>
      ),
      confirmPrompt: "Remove From Publication",
      rejectPrompt: "Cancel",
      onConfirm: sendUnpublish
    });
  };

  let Status, Action;
  if (mayUnpublish) {
    Status = <>&nbsp;</>;
    Action = (
      <a
        href={unpublishHref}
        className="btn btn-outline-danger btn-sm"
        onClick={handleSendUnpublish}
      >
        Remove From Publication
      </a>
    );
  } else if (unpublishingInProgress) {
    Status = <>&nbsp;</>;
    Action = (
      <>
        <i className="fas fa-spinner fa-pulse" /> Removing From Publication
      </>
    );
  } else {
    Status = (
      <div className="mb-2">
        <i>
          If necessary, you may unpublish company data after it has been fully
          released to brands.
        </i>
      </div>
    );
    Action = "";
  }

  let Error;
  if (hasError) {
    Error = (
      <div className="mb-2">
        <i className="fas fa-exclamation-triangle text-warning" />{" "}
        <i>
          There was an error removing the company dashboard from publication
        </i>
      </div>
    );
  } else {
    Error = "";
  }

  return (
    <>
      {Status}
      {Action}
      {Error}
    </>
  );
}

// This will remove all data from this company's dashboard.  Shared risks originating from this company will be removed from other companies dashboards.  Are you sure you want to do this?
