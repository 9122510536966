import * as React from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import ChosenRecommendation from "./ChosenRecommendation";
import { ReactNode } from "react";

/////////////////////////////////////////////
// RecommendationsList
/////////////////////////////////////////////
type RecommendationsListProps = {
  recommendations: string[];
  name: string;
  removeRecommendation: (index: number) => void;
  moveRecommendation: (from: number, to: number) => void;
  getRecommendationId: (r: string) => number;
  disabled: boolean;
};

const RecommendationsList: React.FC<RecommendationsListProps> = props => {
  const {
    recommendations,
    removeRecommendation,
    getRecommendationId,
    moveRecommendation,
    disabled
  } = props;

  const handleDragEnd: (r: DropResult) => void = result => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    moveRecommendation(source.index, destination.index);
  };

  let emptyListHiddenInput: ReactNode | null;
  if (recommendations.length == 0) {
    emptyListHiddenInput = (
      <input
        type="hidden"
        name="cumulus_ddr_section[recommendations]"
        value=""
      />
    );
  } else {
    emptyListHiddenInput = null;
  }

  if (disabled && recommendations.length == 0) {
    return (
      <div className="font-italic">No Recommendations have been entered</div>
    );
  } else {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="recommendations-list">
          {(provided, _snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {emptyListHiddenInput}
              {recommendations.map((val, i) => {
                const handleRemove: React.EventHandler<React.MouseEvent> = event => {
                  event.preventDefault();
                  removeRecommendation(i);
                };
                const id = getRecommendationId(val);
                return (
                  <ChosenRecommendation
                    value={val}
                    key={id}
                    handleRemove={handleRemove}
                    index={i}
                    {...{ id, disabled }}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
};
export default RecommendationsList;
